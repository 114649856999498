import { LoadingStateEnum } from '@dmc-ng/data-access';
import { Action, createReducer, on } from '@ngrx/store';

import { publicActions } from './public.actions';
import {
  CampaignReportingPublicModel,
  CountingVersionPublicModel,
} from '../models/share.model';

export const PUBLIC_FEATURE_KEY = 'public';

export interface PublicState {
  countingVersionPublic: CountingVersionPublicState;
  campaignReportingPublic: CampaignReportingPublicState;
  error?: unknown;
  loading: LoadingStateEnum;
}

interface CountingVersionPublicState {
  countingVersion?: CountingVersionPublicModel;
  error?: unknown;
  loading: LoadingStateEnum;
}

interface CampaignReportingPublicState {
  campaignReporting?: CampaignReportingPublicModel;
  error?: unknown;
  loading: LoadingStateEnum;
}

export const initialPublicState: PublicState = {
  loading: LoadingStateEnum.Ready,
  countingVersionPublic: {
    loading: LoadingStateEnum.Ready,
  },
  campaignReportingPublic: {
    loading: LoadingStateEnum.Ready,
  },
};

const reducer = createReducer(
  initialPublicState,
  on(
    publicActions.getPublicCountingVersion,
    publicActions.getPublicCampaignReporting,
    (state) => ({ ...state, loading: LoadingStateEnum.Loading }),
  ),
  on(
    publicActions.publicCountingVersionRetrievedSuccessfully,
    publicActions.publicCampaignReportingRetrievedSuccessfully,
    (state) => ({ ...state, loading: LoadingStateEnum.Done }),
  ),
  on(
    publicActions.publicCountingVersionFailedToBeRetrieved,
    publicActions.publicCampaignReportingFailedToBeRetrieved,
    (state, { error }) => ({ ...state, error, loading: LoadingStateEnum.Done }),
  ),

  on(publicActions.getPublicCountingVersion, (state) => ({
    ...state,
    countingVersionPublic: {
      ...state.countingVersionPublic,
      loading: LoadingStateEnum.Loading,
      error: undefined,
    },
  })),
  on(
    publicActions.publicCountingVersionRetrievedSuccessfully,
    (state, { summary }) => ({
      ...state,
      countingVersionPublic: {
        ...state.countingVersionPublic,
        countingVersion: summary,
        loading: LoadingStateEnum.Done,
        error: undefined,
      },
    }),
  ),
  on(
    publicActions.publicCountingVersionFailedToBeRetrieved,
    (state, { error }) => ({
      ...state,
      countingVersionPublic: {
        ...state.countingVersionPublic,
        loading: LoadingStateEnum.Done,
        error,
      },
    }),
  ),
  on(publicActions.getPublicCampaignReporting, (state) => ({
    ...state,
    campaignReportingPublic: {
      ...state.campaignReportingPublic,
      loading: LoadingStateEnum.Loading,
      error: undefined,
    },
  })),
  on(
    publicActions.publicCampaignReportingRetrievedSuccessfully,
    (state, { summary }) => ({
      ...state,
      campaignReportingPublic: {
        ...state.campaignReportingPublic,
        campaignReporting: summary,
        loading: LoadingStateEnum.Done,
        error: undefined,
      },
    }),
  ),
  on(
    publicActions.publicCampaignReportingFailedToBeRetrieved,
    (state, { error }) => ({
      ...state,
      campaignReportingPublic: {
        ...state.campaignReportingPublic,
        loading: LoadingStateEnum.Done,
        error,
      },
    }),
  ),
);

export function publicReducer(
  state: PublicState | undefined,
  action: Action,
): PublicState {
  return reducer(state, action);
}
