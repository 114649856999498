import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PUBLIC_FEATURE_KEY, PublicState } from './public.reducers';

export const selectPublicState =
  createFeatureSelector<PublicState>(PUBLIC_FEATURE_KEY);

export const selectPublicShareCountingVersion = createSelector(
  selectPublicState,
  (state) => state.countingVersionPublic.countingVersion,
);

export const selectPublicShareCountingVersionLoading = createSelector(
  selectPublicState,
  (state) => state.countingVersionPublic.loading,
);

export const selectPublicShareCountingVersionError = createSelector(
  selectPublicState,
  (state) => state.countingVersionPublic.error,
);

export const selectPublicShareCampaignReporting = createSelector(
  selectPublicState,
  (state) => state.campaignReportingPublic.campaignReporting,
);

export const selectPublicShareCampaignReportingLoading = createSelector(
  selectPublicState,
  (state) => state.campaignReportingPublic.loading,
);

export const selectPublicShareCampaignReportingError = createSelector(
  selectPublicState,
  (state) => state.campaignReportingPublic.error,
);
