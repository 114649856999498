import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { APIResponse } from '@dmc-ng/data-access';
import { API_BASE_URL } from '@dmc-ng/data-access/injection-token';
import { Observable } from 'rxjs';

import { CountDetailsModel } from './models/count.model';
import { DatasetModel } from './models/dataset.model';
import { DatasetTypeEnum } from './models/enum/dataset-type.enum';
import { DimensionsEnum } from './models/enum/dimensions.enum';
import { CountFilterModel } from './models/filter-operator.model';
import { ImportModel, ImportObject } from './models/import.model';

@Injectable()
export class ProfileService {
  private readonly apiBaseUrl = inject(API_BASE_URL);

  constructor(private readonly httpClient: HttpClient) {}

  getCount(
    organizationId: string,
    accountId: string,
    filters: CountFilterModel,
    withLabel: boolean,
    dimensions?: DimensionsEnum[],
  ): Observable<APIResponse<CountDetailsModel>> {
    let params = new HttpParams();
    if (dimensions) {
      params = params.append('dimensions', dimensions.join(','));
    }
    params = params.append('labels', withLabel);
    return this.httpClient.post<APIResponse<CountDetailsModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/profiles/count`,
      filters,
      { params },
    );
  }

  postDataset(
    organizationId: string,
    accountId: string,
    formData: FormData,
    mode: DatasetTypeEnum,
  ): Observable<APIResponse<DatasetModel>> {
    const params = new HttpParams().append('mode', mode);

    return this.httpClient.post<APIResponse<DatasetModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/profiles/datasets/upload`,
      formData,
      { params },
    );
  }

  postImportProfile(
    organizationId: string,
    accountId: string,
    importObject: ImportObject,
  ): Observable<APIResponse<ImportModel>> {
    return this.httpClient.post<APIResponse<ImportModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/profiles/import-profiles`,
      importObject,
    );
  }

  getImportProfile(
    organizationId: string,
    accountId: string,
    profileImportId: string,
  ): Observable<APIResponse<ImportModel>> {
    return this.httpClient.get<APIResponse<ImportModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/profiles/import-profiles/${profileImportId}`,
    );
  }

  exportCSV(
    organizationId: string,
    accountId: string,
    fields: string[],
    filters: CountFilterModel,
  ): Observable<Blob> {
    const params = new HttpParams().append('fields', fields.join(','));
    return this.httpClient.post(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/profiles/export-csv`,
      filters,
      {
        responseType: 'blob',
        observe: 'body',
        params,
      },
    );
  }
}
