import { Injectable } from '@angular/core';
import { APIResponse } from '@dmc-ng/data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { saveAs } from 'file-saver';
import { catchError, map, of, switchMap } from 'rxjs';

import { profileActions } from './profile.actions';
import { CountDetailsModel } from '../models/count.model';
import { DatasetModel } from '../models/dataset.model';
import { DatasetTypeEnum } from '../models/enum/dataset-type.enum';
import { ImportModel } from '../models/import.model';
import { ProfileService } from '../profile.service';

@Injectable()
export class ProfileEffects {
  getTargetReach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.getTargetReach),
      switchMap(
        ({ organizationId, accountId, filters, withLabel, dimensions }) =>
          this.profileService
            .getCount(organizationId, accountId, filters, withLabel, dimensions)
            .pipe(
              map((result: APIResponse<CountDetailsModel>) =>
                profileActions.targetReachRetrievedSuccessfully({
                  count: (result.data as CountDetailsModel).total,
                  counts: (result.data as CountDetailsModel).counts,
                }),
              ),
              catchError((error) =>
                of(profileActions.targetReachFailedToBeRetrieved({ error })),
              ),
            ),
      ),
    ),
  );

  uploadCountingDatasetCSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.uploadDatasetWithCampaignIds),
      switchMap(
        ({ organizationId, accountId, campaignIds, datasetType, files }) => {
          const formData = new FormData();

          if (files && files.length > 0) {
            files.forEach((file, index) => {
              formData.append(`file-${index}`, file, file.name);
            });
          }

          if (campaignIds && campaignIds.length > 0) {
            formData.append('campaignIds', campaignIds.join(','));
          }

          return this.profileService
            .postDataset(organizationId, accountId, formData, datasetType)
            .pipe(
              map((result: APIResponse<DatasetModel>) =>
                profileActions.datasetWithCampaignIdsSentSuccessfully({
                  datasetId: result?.data?.datasetId as string,
                  datasetName: result?.data?.datasetName as string,
                  datasetMode: result?.data?.datasetMode as DatasetTypeEnum,
                }),
              ),
              catchError((error) =>
                of(
                  profileActions.datasetWithCampaignIdsFailedToBeValidated({
                    error,
                  }),
                ),
              ),
            );
        },
      ),
    ),
  );

  importProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.importProfile),
      switchMap(({ organizationId, accountId, importObject }) =>
        this.profileService
          .postImportProfile(organizationId, accountId, importObject)
          .pipe(
            map((result: APIResponse<ImportModel>) =>
              profileActions.importProfileSuccessfully({
                importProfileId: result?.data?.id as string,
              }),
            ),
            catchError((error) =>
              of(profileActions.importProfileFailedToBeUpload({ error })),
            ),
          ),
      ),
    ),
  );

  getImportProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.getImportProfile),
      switchMap(({ organizationId, accountId, profileImportId }) =>
        this.profileService
          .getImportProfile(organizationId, accountId, profileImportId)
          .pipe(
            map((result: APIResponse<ImportModel>) =>
              profileActions.importProfileRetrievedSuccessfully({
                name: (result.data as ImportModel).name,
                profileCount: (result.data as ImportModel).profileCount,
              }),
            ),
            catchError((error) =>
              of(profileActions.importProfileFailedToBeRetrieved({ error })),
            ),
          ),
      ),
    ),
  );

  exportCSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileActions.exportCSV),
      switchMap(({ organizationId, accountId, fields, name, filters }) =>
        this.profileService
          .exportCSV(organizationId, accountId, fields, filters)
          .pipe(
            map((blob) => {
              const fileName = `export_${name}.csv`;
              saveAs(blob, fileName);

              return profileActions.cSVExportedSuccessfully();
            }),
            catchError((error) =>
              of(profileActions.cSVFailedToBeExported({ error })),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
  ) {}
}
