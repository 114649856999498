import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { NewVersionDialogModel } from '../../models/new-version-dialog.model';

@Component({
  selector: 'dmc-ng-new-version-dialog',
  templateUrl: './new-version-dialog.component.html',
  styleUrls: ['./new-version-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatIconModule],
  standalone: true,
})
export class NewVersionDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: NewVersionDialogModel) {}
}
