export const environment = {
  production: false,
  apiUrl: 'https://dev.api.dekuple-marketing.cloud/v1',
  publicUrl: 'https://dev.api.dekuple-marketing.cloud',
  csvUrl: 'https://dev.assets.dekuple-marketing.cloud/csv_templates',
  firebase: {
    apiKey: 'AIzaSyCNAstMcpfVmrn7cG0iOjHbMUdsQZMR8uM',
    authDomain: 'dev.auth.dekuple-marketing.cloud',
  },
  agGrid: {
    licenseKey:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-073762}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{ADL_partners}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{dmc}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{dmc}_need_to_be_licensed___{dmc}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{16_December_2025}____[v3]_[01]_MTc2NTg0MzIwMDAwMA==e867d91fd0c00a11a543fa3af1e678c0',
  },
};
