import { ReportingModel } from '@dmc-ng/data-access/campaign-reportings';

import { PublicTypeEnum } from '../models/public-type.enum';
import { PublicItem } from '../models/public.model';
import {
  CampaignReportingPublicModel,
  CountingShareModel,
  CountingVersionPublicModel,
} from '../models/share.model';

export class PublicUtils {
  static convertPublicItemsToCountingVersionPublicModel(
    item: PublicItem,
  ): CountingVersionPublicModel | undefined {
    return item.type === PublicTypeEnum.Version
      ? (item.data as CountingVersionPublicModel)
      : undefined;
  }

  static convertPublicItemsToCountingShareModel(
    item: PublicItem,
  ): CountingShareModel | undefined {
    return item.type === PublicTypeEnum.Counting
      ? (item.data as CountingShareModel)
      : undefined;
  }

  static convertPublicItemsToCampaignReportingPublicModel(
    item: PublicItem,
  ): CampaignReportingPublicModel | undefined {
    return item.type === PublicTypeEnum.CampaignReporting
      ? (item.data as CampaignReportingPublicModel)
      : undefined;
  }
}
