export const iconsDefinitions = [
  { name: 'android', path: '../assets/android.svg' },
  { name: 'apple', path: '../assets/apple.svg' },
  { name: 'freddie-sad', path: '../assets/freddie-sad.svg' },
  { name: 'google', path: '../assets/google.svg' },
  { name: 'logo-DK', path: '../assets/logo-DK.svg' },
  { name: 'logo-name-fill', path: '../assets/logo-name-fill.svg' },
  { name: 'logo-name', path: '../assets/logo-name.svg' },
  { name: 'logo', path: '../assets/logo.svg' },
  { name: 'microsoft', path: '../assets/microsoft.svg' },
  { name: 'user2-fill', path: '../assets/user2-fill.svg' },
  // NEW DS
  { name: 'alert-circle', path: '../assets/new-ds/alert-circle.svg' },
  { name: 'alert-triangle', path: '../assets/new-ds/alert-triangle.svg' },
  { name: 'arrow-down-left', path: '../assets/new-ds/arrow-down-left.svg' },
  { name: 'arrow-down-right', path: '../assets/new-ds/arrow-down-right.svg' },
  { name: 'arrow-down', path: '../assets/new-ds/arrow-down.svg' },
  { name: 'arrow-left', path: '../assets/new-ds/arrow-left.svg' },
  { name: 'arrow-right', path: '../assets/new-ds/arrow-right.svg' },
  { name: 'arrow-up-left', path: '../assets/new-ds/arrow-up-left.svg' },
  { name: 'arrow-up-right', path: '../assets/new-ds/arrow-up-right.svg' },
  { name: 'arrow-up', path: '../assets/new-ds/arrow-up.svg' },
  { name: 'bell-01', path: '../assets/new-ds/bell-01.svg' },
  { name: 'book-open', path: '../assets/new-ds/book-open.svg' },
  { name: 'bookmark', path: '../assets/new-ds/bookmark.svg' },
  { name: 'building-02', path: '../assets/new-ds/building-02.svg' },
  { name: 'calendar-minus', path: '../assets/new-ds/calendar-minus.svg' },
  { name: 'calendar', path: '../assets/new-ds/calendar.svg' },
  {
    name: 'check-circle-broken',
    path: '../assets/new-ds/check-circle-broken.svg',
  },
  { name: 'check-circle', path: '../assets/new-ds/check-circle.svg' },
  { name: 'check', path: '../assets/new-ds/check.svg' },
  { name: 'chevron-down', path: '../assets/new-ds/chevron-down.svg' },
  { name: 'chevron-left', path: '../assets/new-ds/chevron-left.svg' },
  { name: 'chevron-right', path: '../assets/new-ds/chevron-right.svg' },
  { name: 'chevron-up', path: '../assets/new-ds/chevron-up.svg' },
  { name: 'click', path: '../assets/new-ds/click.svg' },
  { name: 'clock', path: '../assets/new-ds/clock.svg' },
  { name: 'copy-06', path: '../assets/new-ds/copy-06.svg' },
  { name: 'copy-07', path: '../assets/new-ds/copy-07.svg' },
  { name: 'credit-card', path: '../assets/new-ds/credit-card.svg' },
  { name: 'cube-01', path: '../assets/new-ds/cube-01.svg' },
  { name: 'dots-horizontal', path: '../assets/new-ds/dots-horizontal.svg' },
  { name: 'dots-vertical', path: '../assets/new-ds/dots-vertical.svg' },
  { name: 'download-01', path: '../assets/new-ds/download-01.svg' },
  { name: 'download-cloud-02', path: '../assets/new-ds/download-cloud-02.svg' },
  { name: 'drag', path: '../assets/new-ds/drag.svg' },
  { name: 'edit-02', path: '../assets/new-ds/edit-02.svg' },
  { name: 'edit-05', path: '../assets/new-ds/edit-05.svg' },
  { name: 'eye-off', path: '../assets/new-ds/eye-off.svg' },
  { name: 'eye', path: '../assets/new-ds/eye.svg' },
  { name: 'file-04', path: '../assets/new-ds/file-04.svg' },
  { name: 'filter-tunnel-01', path: '../assets/new-ds/filter-tunnel-01.svg' },
  { name: 'filter-lines', path: '../assets/new-ds/filter-lines.svg' },
  { name: 'flip-backward', path: '../assets/new-ds/flip-backward.svg' },
  { name: 'flip-forward', path: '../assets/new-ds/flip-forward.svg' },
  { name: 'globe', path: '../assets/new-ds/globe.svg' },
  { name: 'help-circle', path: '../assets/new-ds/help-circle.svg' },
  { name: 'home-line', path: '../assets/new-ds/home-line.svg' },
  { name: 'image-01', path: '../assets/new-ds/image-01.svg' },
  { name: 'info-circle', path: '../assets/new-ds/info-circle.svg' },
  { name: 'key', path: '../assets/new-ds/key.svg' },
  { name: 'layers-three-01', path: '../assets/new-ds/layers-three-01.svg' },
  { name: 'layout-grid-02', path: '../assets/new-ds/layout-grid-02.svg' },
  { name: 'layout-left', path: '../assets/new-ds/layout-left.svg' },
  { name: 'layout-right', path: '../assets/new-ds/layout-right.svg' },
  { name: 'link-03', path: '../assets/new-ds/link-03.svg' },
  { name: 'link', path: '../assets/new-ds/link.svg' },
  { name: 'list', path: '../assets/new-ds/list.svg' },
  { name: 'log-in', path: '../assets/new-ds/log-in.svg' },
  { name: 'log-out', path: '../assets/new-ds/log-out.svg' },
  { name: 'mail-01', path: '../assets/new-ds/mail-01.svg' },
  { name: 'map-01', path: '../assets/new-ds/map-01.svg' },
  { name: 'map-02', path: '../assets/new-ds/map-02.svg' },
  { name: 'mark', path: '../assets/new-ds/mark.svg' },
  { name: 'minus', path: '../assets/new-ds/minus.svg' },
  { name: 'phone-1', path: '../assets/new-ds/phone-1.svg' },
  { name: 'pie-chart-02', path: '../assets/new-ds/pie-chart-02.svg' },
  { name: 'plus', path: '../assets/new-ds/plus.svg' },
  { name: 'refresh-ccw-04', path: '../assets/new-ds/refresh-ccw-04.svg' },
  { name: 'refresh-ccw-05', path: '../assets/new-ds/refresh-ccw-05.svg' },
  { name: 'rocket-01', path: '../assets/new-ds/rocket-01.svg' },
  { name: 'save', path: '../assets/new-ds/save.svg' },
  { name: 'search-lg', path: '../assets/new-ds/search-lg.svg' },
  { name: 'search-md', path: '../assets/new-ds/search-md.svg' },
  { name: 'settings-04', path: '../assets/new-ds/settings-04.svg' },
  { name: 'settings', path: '../assets/new-ds/settings.svg' },
  { name: 'share', path: '../assets/new-ds/share.svg' },
  { name: 'share-02', path: '../assets/new-ds/share_02.svg' },
  { name: 'sms', path: '../assets/new-ds/sms.svg' },
  {
    name: 'switch-vertical-01',
    path: '../assets/new-ds/switch-vertical-01.svg',
  },
  {
    name: 'switch-vertical-02',
    path: '../assets/new-ds/switch-vertical-02.svg',
  },
  { name: 'trash-03', path: '../assets/new-ds/trash-03.svg' },
  { name: 'trash-04', path: '../assets/new-ds/trash-04.svg' },
  { name: 'upload-01', path: '../assets/new-ds/upload-01.svg' },
  { name: 'upload-cloud-02', path: '../assets/new-ds/upload-cloud-02.svg' },
  { name: 'user-03', path: '../assets/new-ds/user-03.svg' },
  { name: 'user-minus', path: '../assets/new-ds/user-minus.svg' },
  { name: 'user-plus', path: '../assets/new-ds/user-plus.svg' },
  { name: 'users-02', path: '../assets/new-ds/users-02.svg' },
  { name: 'users-up', path: '../assets/new-ds/users-up.svg' },
  { name: 'variant67', path: '../assets/new-ds/variant67.svg' },
  { name: 'wallet', path: '../assets/new-ds/wallet.svg' },
  { name: 'x-circle', path: '../assets/new-ds/x-circle.svg' },
  { name: 'x-close', path: '../assets/new-ds/x-close.svg' },
  { name: 'step-base', path: '../assets/new-ds/steps/step-base.svg' },
  { name: 'step-current', path: '../assets/new-ds/steps/step-current.svg' },
];
