import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { publicActions } from './public.actions';
import { CountingVersionPublicModel } from '../models/share.model';
import { PublicService } from '../public.service';
import { PublicUtils } from '../utils/public.utils';

@Injectable()
export class PublicEffects {
  publicCountingVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publicActions.getPublicCountingVersion),
      switchMap(({ token }) =>
        this.publicService.getVersionCounting(token).pipe(
          map(({ data }) =>
            publicActions.publicCountingVersionRetrievedSuccessfully({
              summary:
                PublicUtils.convertPublicItemsToCountingVersionPublicModel(
                  data.items[0],
                ),
            }),
          ),
          catchError((error) =>
            of(
              publicActions.publicCountingVersionFailedToBeRetrieved({ error }),
            ),
          ),
        ),
      ),
    ),
  );

  publicCampaignReporting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publicActions.getPublicCampaignReporting),
      switchMap(({ token }) =>
        this.publicService.getVersionCounting(token).pipe(
          map(({ data }) =>
            publicActions.publicCampaignReportingRetrievedSuccessfully({
              summary:
                PublicUtils.convertPublicItemsToCampaignReportingPublicModel(
                  data.items[0],
                ),
            }),
          ),
          catchError((error) =>
            of(
              publicActions.publicCampaignReportingFailedToBeRetrieved({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private publicService: PublicService,
  ) {}
}
