<div class="d-flex justify-content-center align-items-center w-100 h-100">
  <div
    class="reset-password d-flex flex-column"
    [ngClass]="{
      'card-on-success-or-failed':
        !isCodeValid ||
        isPasswordSuccessfullyUpdated ||
        isPasswordFailedToBeUpdated
    }"
  >
    @if (isCodeValid && !isPasswordSuccessfullyUpdated) {
      <dmc-ng-icon
        class="d-flex align-self-center logo-block"
        [color]="iconColorEnum.Primary"
        [size]="iconSizeEnum.XL"
        [theme]="iconThemeEnum.Modern"
        icon="key"
      ></dmc-ng-icon>

      <div class="header-block d-flex flex-column align-items-center gap-2">
        <span class="title">Set new password</span>
        <span class="description">
          Set your new password to access the platform.
        </span>
      </div>

      <form
        [formGroup]="resetPasswordForm"
        (ngSubmit)="onSubmit()"
        class="d-flex flex-column gap-6"
      >
        <div class="d-flex flex-column gap-3">
          <dmc-ng-input
            label="Enter new password"
            [type]="canShowNewPassword ? 'password' : 'text'"
            formControlName="newPassword"
            customError="Password must meet the criteria"
          >
            <mat-icon
              class="icons password-icon material-icons-outlined gray-stroke-500 password-icon"
              [svgIcon]="canShowNewPassword ? 'eye-off' : 'eye'"
              (click)="showNewPassword()"
            ></mat-icon>
          </dmc-ng-input>

          <dmc-ng-input
            label="Confirm new password"
            [type]="canShowConfirmPassword ? 'password' : 'text'"
            formControlName="confirmPassword"
            customError="Passwords must match"
          >
            <mat-icon
              class="icons password-icon material-icons-outlined gray-stroke-500 password-icon"
              [svgIcon]="canShowConfirmPassword ? 'eye-off' : 'eye'"
              (click)="showConfirmPassword()"
            ></mat-icon>
          </dmc-ng-input>
        </div>

        <div class="d-flex flex-row w-100 validation-block">
          <!-- MIN LENGTH -->
          <div class="d-flex flex-row validation-info align-items-center gap-2">
            <dmc-ng-icon
              [color]="
                isMinLengthValid ? iconColorEnum.Brand : iconColorEnum.White
              "
              [size]="iconSizeEnum.XS"
              [theme]="iconThemeEnum.LightCircle"
              icon="check"
            ></dmc-ng-icon>
            <span class="chip-label">8 characters</span>
          </div>
          <!-- NUMBER -->
          <div class="d-flex flex-row validation-info align-items-center gap-2">
            <dmc-ng-icon
              [color]="
                isNumberValid ? iconColorEnum.Brand : iconColorEnum.White
              "
              [size]="iconSizeEnum.XS"
              [theme]="iconThemeEnum.LightCircle"
              icon="check"
            ></dmc-ng-icon>
            <span class="chip-label">1 number</span>
          </div>
          <!-- UPPERCASE -->
          <div class="d-flex flex-row validation-info align-items-center gap-2">
            <dmc-ng-icon
              [color]="
                isUppercaseValid ? iconColorEnum.Brand : iconColorEnum.White
              "
              [size]="iconSizeEnum.XS"
              [theme]="iconThemeEnum.LightCircle"
              icon="check"
            ></dmc-ng-icon>
            <span class="chip-label">1 uppercase</span>
          </div>
          <!-- SPECIAL CHAR -->
          <div class="d-flex flex-row validation-info align-items-center gap-2">
            <dmc-ng-icon
              [color]="
                isSpecialCharValid ? iconColorEnum.Brand : iconColorEnum.White
              "
              [size]="iconSizeEnum.XS"
              [theme]="iconThemeEnum.LightCircle"
              icon="check"
            ></dmc-ng-icon>
            <span class="chip-label">1 special character</span>
          </div>
          <!-- LOWERCASE -->
          <div class="d-flex flex-row validation-info align-items-center gap-2">
            <dmc-ng-icon
              [color]="
                isLowercaseValid ? iconColorEnum.Brand : iconColorEnum.White
              "
              [size]="iconSizeEnum.XS"
              [theme]="iconThemeEnum.LightCircle"
              icon="check"
            ></dmc-ng-icon>
            <span class="chip-label">1 lowercase</span>
          </div>
        </div>

        <button
          mat-raised-button
          color="primary"
          [disabled]="resetPasswordForm.invalid"
          type="submit"
          class="large full-width"
        >
          <span class="label-button">Continue</span>
        </button>

        <div
          class="d-flex flex-row back-to-login align-self-center align-items-center gap-2"
          (click)="redirectToLogin()"
        >
          <mat-icon
            class="icons gray-stroke-700"
            svgIcon="arrow-left"
          ></mat-icon>
          <span>Back to log in</span>
        </div>
      </form>
    } @else if (!isCodeValid || isPasswordFailedToBeUpdated) {
      <div class="d-flex flex-column gap-2">
        <h3>Set new password</h3>
        <span class="description">
          @if (!isCodeValid) {
            Invalid or expired validation code. Please try again.
          } @else if (isPasswordFailedToBeUpdated) {
            An unexpected error ooccured. Please try again.
          }
        </span>
      </div>
      <button
        mat-raised-button
        color="primary"
        (click)="redirectToLogin()"
        class="large custom-btn"
      >
        <span class="label-button">Try again</span>
      </button>
    } @else if (isPasswordSuccessfullyUpdated) {
      <div class="d-flex flex-column gap-2">
        <h3>You’re all set to Login !</h3>
        <span class="description">
          You’re new password is ready for you to use.
        </span>
      </div>
      <button
        mat-raised-button
        color="primary"
        (click)="redirectToLogin()"
        class="large custom-btn"
      >
        <span class="label-button">Login</span>
      </button>
    }
  </div>
</div>
