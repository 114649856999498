<div
  class="top"
  mat-dialog-title
>
  <h3>A new version is available !</h3>
</div>
<mat-dialog-content>
  <p>
    A new version of the application is available. To take advantage of the
    latest improvements, please reload the application.
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    class="medium button"
    (click)="data.clickOnReload()"
    [autofocus]="false"
  >
    Reload
  </button>
</mat-dialog-actions>
