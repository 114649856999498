<div
  class="top"
  mat-dialog-title
>
  <div class="logo-title">
    <dmc-ng-icon
      [color]="iconColorEnum.Warning"
      [theme]="iconThemeEnum.LightCircleOutline"
      [size]="iconSizeEnum.LG"
      icon="save"
    ></dmc-ng-icon>
    <h3>Unsaved changes</h3>
  </div>

  <button
    mat-icon-button
    aria-label="Close button"
    (click)="data.clickOnCancel()"
    class="icon-button"
  >
    <mat-icon
      class="icon gray-stroke-400"
      svgIcon="x-close"
    ></mat-icon>
  </button>
</div>
<mat-dialog-content>
  <p>If you leave, you'll lose your changes.</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-stroked-button
    color="secondary"
    class="medium"
    (click)="data.clickOnCancel()"
    [autofocus]="false"
  >
    Cancel
  </button>
  <button
    mat-raised-button
    color="primary"
    class="medium"
    (click)="data.clickOnLeave()"
  >
    Leave without saving
  </button>
</mat-dialog-actions>
