<div
  class="top"
  mat-dialog-title
>
  <div class="logo-title">
    <dmc-ng-icon
      [color]="iconColorEnum.Error"
      [theme]="iconThemeEnum.LightCircleOutline"
      [size]="iconSizeEnum.LG"
      icon="trash-03"
    ></dmc-ng-icon>
    <h3>Delete {{ data.type }}</h3>
  </div>
  <button
    mat-icon-button
    aria-label="Close button"
    (click)="data.clickOnCancel()"
    class="icon-button"
  >
    <mat-icon
      class="icon gray-stroke-400"
      svgIcon="x-close"
    ></mat-icon>
  </button>
</div>
<mat-dialog-content>
  <p>Do you want to delete the {{ data.type }} named "{{ data.name }}" ?</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-stroked-button
    color="secondary"
    class="medium button"
    (click)="data.clickOnCancel()"
    [autofocus]="false"
  >
    Cancel
  </button>
  <button
    mat-raised-button
    color="primary"
    class="medium button destructive"
    (click)="data.clickOnDelete()"
    [autofocus]="false"
  >
    Delete
  </button>
</mat-dialog-actions>
