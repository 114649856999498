import { createActionGroup, props } from '@ngrx/store';

import {
  CampaignReportingPublicModel,
  CountingVersionPublicModel,
} from '../models/share.model';

export const publicActions = createActionGroup({
  source: 'Public',
  events: {
    'Get Public Counting Version': props<{
      token: string;
    }>(),
    'Public Counting Version retrieved successfully': props<{
      summary?: CountingVersionPublicModel;
    }>(),
    'Public Counting Version failed to be retrieved': props<{
      error: unknown;
    }>(),
    'Get Public Campaign Reporting': props<{
      token: string;
    }>(),
    'Public Campaign Reporting retrieved successfully': props<{
      summary?: CampaignReportingPublicModel;
    }>(),
    'Public Campaign Reporting failed to be retrieved': props<{
      error: unknown;
    }>(),
  },
});
